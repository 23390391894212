/**
 * This represents some generic auth provider API, like Firebase.
 */

import Cookies from "js-cookie";
import { login, logout } from "./services/authService";

const fakeAuthProvider = {
  isAuthenticated: !(
    Cookies.get("token") === "" || Cookies.get("token") === undefined
  ),
  username: localStorage.getItem("username") ?? "",
  async signin(username: string, password: string, callback: VoidFunction) {
    try {
      await login(username, password);
      fakeAuthProvider.isAuthenticated = true;
      setTimeout(callback, 100);
    } catch (error) {
      throw error;
    }
  },
  async signout(callback: VoidFunction) {
    try {
      await logout();
      fakeAuthProvider.isAuthenticated = false;
      setTimeout(callback, 100);
    } catch (error) {
      alert("An error occurred during logout. Please try again.");
    }
  },
};

export { fakeAuthProvider };
