import React, { useState } from "react";
import { fakeAuthProvider } from "../auth";

interface AuthContextType {
  username: string | null;
  signin: (user: string, password: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [username, setUsername] = useState<string | null>(
    fakeAuthProvider.username
  );

  let signin = (username: string, password: string, callback: VoidFunction) => {
    return fakeAuthProvider.signin(username, password, () => {
      setUsername(username);
      callback();
    });
  };

  let signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setUsername(null);
      callback();
    });
  };

  let value = {
    username,
    signin,
    signout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

export { AuthProvider, useAuth };
