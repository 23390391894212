import Cookies from "js-cookie";
import { RequestMethod } from "../constants/RestMethods";
import { makeRequest } from "./requestService";

async function setCookie(key: string, value: string) {
  const isSecure = window.location.protocol === "https:";
  document.cookie =
    `${key}=${value}; path=/; samesite=strict` + (isSecure && "; secure");
}

async function removeCookie(key: string) {
  Cookies.remove(key);
}

const generateToken = async (
  username: string,
  password: string
): Promise<string> => {
  const data = await makeRequest(
    "security/generate_token",
    RequestMethod.POST,
    {
      username: `${username}`,
      password: `${password}`,
    },
    true
  );

  return data.token;
};

export const createAccount = async (
  username: string,
  password: string,
  displayName?: string
): Promise<void> => {
  let requestBody = {
    username: `${username}`,
    password: `${password}`,
  };

  if (displayName) {
    requestBody = {
      ...requestBody,
      display_name: `${displayName}`,
    } as {
      username: string;
      password: string;
      display_name: string;
    };
  }
  await makeRequest("account", RequestMethod.POST, requestBody, true);
};

const createPlayerProfile = async (sport: string = "SOCCER"): Promise<void> => {
  await makeRequest("profiles", RequestMethod.POST, {
    sport,
  });
};

export const getAccount = async (): Promise<any> => {
  return await makeRequest("account", RequestMethod.GET);
};

export const signUp = async (
  username: string,
  password: string,
  displayName: string
): Promise<void> => {
  try {
    await createAccount(username, password, displayName);
    await setCookie("token", await generateToken(username, password));
    await createPlayerProfile();
  } catch (error) {
    removeCookie("token");
    throw error;
  }
};

export const login = async (
  username: string,
  password: string
): Promise<void> => {
  try {
    await setCookie("token", await generateToken(username, password));
    const account = await getAccount();
    localStorage.setItem("username", account.username);
  } catch (error) {
    removeCookie("token");
    localStorage.removeItem("username");
    throw error;
  }
};

export const logout = async (): Promise<void> => {
  removeCookie("token");
  localStorage.removeItem("username");
};
