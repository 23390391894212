import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { DateTimePicker, DateTimeValidationError } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { useEffect, useState } from "react";
import { RequestMethod } from "../../constants/RestMethods";
import { PickupEvent } from "../../interfaces/PickupEvent";
import { makeRequest } from "../../services/requestService";
import { mapEvent } from "../../utils/eventUtils";

type HostedEventsProps = {
  isEventsUpdated: boolean;
};

export const HostedEvents: React.FC<HostedEventsProps> = ({
  isEventsUpdated,
}) => {
  const [pickupEvents, setPickupEvents] = useState<PickupEvent[]>([]);

  // These state variables are used to populate the edit event dialog
  const [open, setOpen] = React.useState(false);
  const [currentEvent, setCurrentEvent] = useState<
    PickupEvent | null | undefined
  >(null);
  const [sport] = React.useState("soccer");
  const [eventName, setEventName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [venueName, setVenueName] = React.useState("");
  const [minGuests, setMinGuests] = React.useState(3);
  const [maxGuests, setMaxGuests] = React.useState<number | undefined>(
    undefined
  );
  const [privacy, setPrivacy] = React.useState("PUBLIC");
  function handlePrivacyChange(event: SelectChangeEvent<string>): void {
    setPrivacy(event.target.value as string);
  }
  const latitude = 0;
  const longitude = 0;
  // Set the start time to be 1 hour from now and round to the nearest hour
  const [startDateTime, setStartDateTime] = useState<Dayjs | null>(null);
  const [endDateTime, setEndDateTime] = useState<Dayjs | null>(null);

  const [startDateTimeError, setStartDateTimeError] =
    React.useState<DateTimeValidationError | null>(null);
  const [endDateTimeError, setEndDateTimeError] =
    React.useState<DateTimeValidationError | null>(null);

  const startDateTimeErrorMessage = React.useMemo(() => {
    switch (startDateTimeError) {
      case "disablePast": {
        return "Date cannot be in the past";
      }

      case "invalidDate": {
        return "Invalid date";
      }

      default: {
        return "";
      }
    }
  }, [startDateTimeError]);

  const endDateTimeErrorMessage = React.useMemo(() => {
    switch (endDateTimeError) {
      case "disablePast": {
        return "Date cannot be in the past";
      }

      case "minDate": {
        return "Date cannot be earlier than the start date";
      }

      case "invalidDate": {
        return "Invalid date";
      }

      default: {
        return "";
      }
    }
  }, [endDateTimeError]);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            backgroundColor: "none",
          },
        },
      },
    },
  });

  const handleDeleteClick = React.useCallback(
    (id: GridRowId) => async () => {
      try {
        await makeRequest(`events/${id}`, RequestMethod.DELETE);
        setPickupEvents((prevPickupEvents) =>
          prevPickupEvents.filter((e) => e.id !== id)
        );
      } catch (error) {
        console.error(error);
        alert("An error occurred while deleting the event. Please try again.");
      }
    },
    []
  );

  const handleEditClick = React.useCallback(
    (id: GridRowId) => async () => {
      const targetEvent = pickupEvents.find((event) => event.id === id);
      if (!targetEvent) {
        alert("Event not found");
        return;
      } else {
        setCurrentEvent(targetEvent);
        setEventName(targetEvent.name);
        setDescription(targetEvent.description);
        setVenueName(targetEvent.venue.name);
        setMinGuests(targetEvent.settings.minGuests);
        setMaxGuests(targetEvent.settings.maxGuests);
        setPrivacy(targetEvent.settings.privacy);
        setStartDateTime(targetEvent.startTime);
        setEndDateTime(targetEvent.endTime);
      }

      handleOpen();
    },
    [pickupEvents]
  );

  // When the user clicks save, send a PUT request to the backend
  const sendEditRequest = () => {
    const id = currentEvent?.id;
    const body = {
      id: id,
      name: eventName,
      description: description,
      sport: sport,
      start_time: startDateTime ? startDateTime.valueOf() : null,
      end_time: endDateTime ? endDateTime.valueOf() : null,
      venue: {
        name: venueName,
        latitude: latitude,
        longitude: longitude,
      },
      settings: {
        privacy: privacy,
        min_guests: minGuests,
        max_guests: maxGuests,
      },
    };
    makeRequest(`events/${id}`, RequestMethod.PUT, body)
      .then((response) => {
        // Assuming the response constains the updated event
        const updatedEvent = mapEvent(response);

        // Update the state with the updated event
        setPickupEvents(
          pickupEvents.map((event) => {
            return event.id === id ? updatedEvent : event;
          })
        );

        // Show a success message
        alert("Event edited successfully!");

        // Close the dialog
        handleClose();
      })
      .catch(() => {
        alert("Failed to edit event. Please try again.");
      });
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "sport", headerName: "Sport", flex: 1 },
    {
      field: "venue",
      headerName: "Venue",
      flex: 1,
      valueGetter: (params) => {
        return params.row.venue.name;
      },
    },
    {
      field: "privacy",
      headerName: "Privacy",
      flex: 1,
      valueGetter: (params) => {
        return params.row.settings.privacy;
      },
    },
    {
      field: "startTime",
      headerName: "Start Time",
      flex: 1,
      valueGetter: (params) => {
        return params.row.startTime.format("ddd, MMM D, YYYY h:mm A");
      },
    },
    {
      field: "endTime",
      headerName: "End Time",
      flex: 1,
      valueGetter: (params) => {
        return params.row.endTime.format("ddd, MMM D, YYYY h:mm A");
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      hideable: false,
      getActions: (params) => [
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(params.id)}
          showInMenu
        />,
        <GridActionsCellItem
          key="edit"
          icon={<EditIcon />}
          label={"Edit"}
          onClick={handleEditClick(params.id)}
          showInMenu
        />,
      ],
    },
  ];

  useEffect(() => {
    makeRequest("events?hosted=true&completed=false", RequestMethod.GET)
      .then((response: any) => {
        setPickupEvents(response.events.map(mapEvent) as PickupEvent[]);
      })
      .catch((error: Error) => {
        console.error(error);
      });
  }, [isEventsUpdated]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);

    // Reset the form
    setEventName("");
    setDescription("");
    setVenueName("");
    setMinGuests(3);
    setMaxGuests(undefined);
    setPrivacy("public");
    setStartDateTime(dayjs().add(1, "hour").minute(0).second(0).millisecond(0));
    setEndDateTime(dayjs().add(1, "hour").minute(0).second(0).millisecond(0));
  };

  let minGuestsHelperText = () => {
    if (!minGuests) {
      return "Minimum guests is required";
    } else if (minGuests < 3) {
      return "Minimum guests cannot be less than 3";
    } else {
      return undefined;
    }
  };

  let maxGuestsHelperText = () => {
    if (maxGuests === undefined) {
      return undefined;
    } else if (maxGuests < 1) {
      return "Maximum guests is required";
    } else if (maxGuests < minGuests) {
      return "Maximum guests cannot be less than minimum guests";
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {pickupEvents.length > 0 ? (
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid rows={pickupEvents} columns={columns} autoPageSize />
        </Box>
      ) : (
        <Typography variant="h6">No events found.</Typography>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>Update event</DialogTitle>
        <DialogContent>
          <Stack spacing={1.5}>
            <TextField
              autoFocus
              margin="dense"
              label="Title"
              fullWidth
              required
              value={eventName}
              onChange={(event) => {
                setEventName(event.target.value);
              }}
              style={{ marginTop: "2" }}
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              required={false}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              // type allows multiline text
              type="text"
            />
            <TextField
              margin="dense"
              label={"Sport"}
              fullWidth
              InputProps={{ readOnly: true }}
              value={sport.charAt(0).toUpperCase() + sport.slice(1)}
            />
            <TextField
              margin="dense"
              label="Location"
              fullWidth
              required
              value={venueName}
              onChange={(event) => {
                setVenueName(event.target.value);
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disablePast
                label="Starts"
                onChange={(newValue) => setStartDateTime(newValue)}
                onError={(newError) => setStartDateTimeError(newError)}
                slotProps={{
                  textField: {
                    helperText: startDateTimeErrorMessage,
                  },
                }}
                value={startDateTime}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disablePast
                label="Ends"
                minDateTime={startDateTime}
                onChange={(newValue) => setEndDateTime(newValue)}
                onError={(newError) => setEndDateTimeError(newError)}
                slotProps={{
                  textField: {
                    helperText: endDateTimeErrorMessage,
                  },
                }}
                value={endDateTime}
              />
            </LocalizationProvider>
            <TextField
              margin="dense"
              label={"Minimum guests"}
              fullWidth
              value={minGuests}
              onChange={(event) => {
                setMinGuests(parseInt(event.target.value));
              }}
              type="number"
              inputProps={{ min: 3 }}
              error={!minGuests || minGuests < 3}
              helperText={minGuestsHelperText()}
              required
            />
            <TextField
              margin="dense"
              label={"Maximum guests"}
              fullWidth
              value={maxGuests}
              onChange={(event) => {
                setMaxGuests(parseInt(event.target.value));
              }}
              type="number"
              inputProps={{ min: minGuests }}
              error={
                maxGuests === undefined || maxGuests === null
                  ? false
                  : maxGuests < minGuests
              }
              helperText={maxGuestsHelperText()}
              required
            />
            <FormControl fullWidth required margin="dense">
              <InputLabel>Privacy</InputLabel>
              <Select
                value={privacy}
                onChange={handlePrivacyChange}
                label="Privacy"
                required
              >
                <MenuItem value={"PUBLIC"}>Public</MenuItem>
                <MenuItem value={"INVITATION_ONLY"}>Private</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={sendEditRequest}>Save</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};
